@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
    @apply font-sans;
}

/**
 * Applies teal color to the AG Grid Checkbox
 */
.ag-theme-balham .ag-checkbox-input-wrapper::after,
.ag-theme-balham .ag-checkbox-input-wrapper.ag-checked::after {
    @apply !text-teal;
}

/**
 * Fix for .group class coming from querybuilder
 */
.group:not(.query-builder-container .group){
    background: inherit;
    border: inherit;
}

/**
 * Fix for query builder rules not showing value fields when they are wrapped
 */
.query-builder-container .rule--body--wrapper {
    @apply h-full;
}


/**
 * Applies custom styles to the Ant TreeSelect enabled option title
 */
.ant-select-tree  .ant-select-tree-node:not(.ant-select-tree-treenode-disabled) .ant-select-tree-node-content-wrapper {
    color: #3B4446 !important;
};

/**
 * Applies custom styles to the Ant TreeSelect disabled option title
 */
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: #7B868A !important;
    cursor: default !important;
}



#folio-page *,
#sample-card * {
    font-family: Arial;
}
