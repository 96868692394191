@import '~antd/dist/antd.less';

.ant-modal-close-x {
  display: flex;
  align-items: center;
}
.anticon {
  vertical-align: baseline;
}

.ant-alert.ant-alert-error {
  overflow: scroll;
}

.dropdown-filter-select {
  .ant-select-selector {
    border-radius: 5px !important;
  }
}

#gridModal {
  .set-fixed-width {
    width: 22% !important;
    height: 75%;
    overflow: hidden;
    overflow-y: scroll;
    float: left;
  }
  
  .hero-image-modal {
    width: 78%;
    float: left;
  }
}
@primary-color: #2FACB2;@border-color-base: @primary-color;@modal-header-bg: @primary-color;@modal-heading-color: #FFF;@modal-close-color: #FFF;@base-border-radius: 4px;